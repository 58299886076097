const baseURL = 'http://77.85.34.107:3000'
const collabURL = 'http://77.85.34.107:8085'
// const baseURL = process.env.VUE_APP_BACKEND_PATH || 'http:/localhost:3000/'
// const collabURL = process.env.VUE_APP_COLLABORATION_PATH || 'ws://localhost:8085'
const usedLanguages = ['en', 'fr', 'de', 'bg', 'es', 'it', 'ro']
const preices = {
  singleAccountPreis: 89,
  companyAccountPreis: 99,
  storageHundredGbPreis: 29,
  storageThousandGbPreis: 59
}
const storageData = {
  singleStorage: 5,
  companyStorage: 150
}

export default {
  baseURL,
  collabURL,
  usedLanguages,
  preices,
  storageData
}
